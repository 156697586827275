import { Injectable } from '@angular/core';
import { mockSliders } from '../../configs/sliders.config';
import { SlidersService } from 'sostereo-services';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CachedDataService {
  public landingSlider: any;
  public landingSliderUpdate = new Subject<void>();

  constructor(private slidersService: SlidersService, private commonService: CommonService) {}

  public getLandingSlider() {
    const slidersQuery = { segments: 'landing', itemsLimit: 12, itemsPage: 1, limit: 1 };
    this.slidersService
      .getSliders(slidersQuery)
      .pipe(
        finalize(() => {
          this.landingSlider.landingSlider = true;
          this.landingSliderUpdate.next();
        }),
      )
      .subscribe(
        (sRes) => {
          if (sRes.data.items.length > 0) {
            this.landingSlider = sRes.data.items[0];
            this.mapLandingSlider();
            return;
          }
          this.landingSlider = mockSliders[0];
        },
        () => {
          this.landingSlider = mockSliders[0];
        },
      );
  }

  private mapLandingSlider() {
    const responsiveConfig = [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 1231,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ];
    this.landingSlider.settings.slidesToShow = 3;
    this.landingSlider.settings.slidesToScroll = 1;
    this.landingSlider.settings.arrows = false;
    this.landingSlider.settings.responsive = responsiveConfig;
    this.landingSlider.settings.lazyLoad = 'ondemand';
    this.landingSlider.items.forEach((sItem) => {
      if (
        sItem.metrics &&
        sItem.metrics.spotify &&
        sItem.metrics.spotify.monthlyListeners &&
        sItem.metrics.spotify.monthlyListeners > 0
      ) {
        sItem.metrics.spotify.monthlyListeners = this.commonService.addLetterToLargeNumber(
          sItem.metrics.spotify.monthlyListeners,
        );
      }
    });
  }
}
